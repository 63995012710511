import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      password: null,
      password_confirm: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => this.vm?.$t('action.reset-password'),
      defaultData: this._defaultData,
      disableRead: true,
      blocks: [
        {
          data: {
            password: {
              required: true,
              label: 'site_admin.data.password',
              type: 'text',
              password: true,
              // @ts-ignore
              validatePasswordStrength: this.vm.$store.getters['base/feature'].iso27001 === true,
              help: 'password.help',
              minlength: 4,
            },
            password_confirm: {
              required: true,
              label: 'site_admin.data.password_confirm',
              type: 'text',
              minlength: 4,
              password: true,
            },
          },
        },
      ],
      dataAction: {
        status: false,
        delete: false,
        refresh: false,
      },
    }
  }
}

export default new formConfig()

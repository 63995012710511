<template>
  <eagle-form
      :form-key="formKey"
  ></eagle-form>
</template>

<script>
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './resetPassword'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'reset-password-form',
    meta: {},
  }),
  methods: {
    async updateApi(target, formData) {
      return await this.$api.collection.siteAdminAccountApi.resetPassword(formData.password, formData.password_confirm)
    },
    getFormConfig() {
      return formConfig
    },
    async afterSaveSuccessCallback(result) {
      this.$tokenStore.set(result.token)
      const queryPath = this.$route.query.path
      if(queryPath) {
        this.$router.replace({ path: queryPath })
      }

      else {
        this.$router.replace({ name: 'admin.entry' })
      }
    },
    afterSaveFailCallback(status, errorJson) {
      if(status === 409) return this.$t(`validate.error.old_password_cannot_be_used`)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
